<template>
  <div>
    <div class="md-modal md-effect-11 md-show">
      <div class="md-content">
        <h3 class="bg-dark">
          {{ $t("transaction.transfer_details") }} -
          <span class="f-16 d-inline d-sm-none">{{ transfer.reference }}</span>
          <span class="d-none d-sm-inline">{{ transfer.reference }}</span>
        </h3>
        <div class="card-body p-0">
          <table class="table table-striped table-hover">
            <tbody>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("transaction.sender") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ isSender ? $t("common.me") : transfer.sender.email }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("transaction.receiver_1") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  <span v-if="isReceiver">{{ $t("common.me") }}</span>
                  <span v-else>
                    <span class="text-capitalize">
                      {{
                        transfer.receiver.nom + " " + transfer.receiver.prenom
                      }}
                    </span>
                    <em class="d-block f-10 text-secondary">
                      {{ transfer.receiver.email }}
                    </em>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>
                    {{ $t("common.amount") }}
                    <span class="d-none d-sm-inline">
                      {{ isSender ? $t("common.sent") : $t("common.received") }}
                    </span>
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{
                    `${transfer.base_amount} ${transfer.target_currency ||
                      transfer.currency}`
                  }}
                </td>
              </tr>
              <tr v-if="isSender">
                <td class="align-middle text-right">
                  <i>{{ $t("common.fees") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ `${transfer.fee_amount} ${transfer.currency}` }}
                </td>
              </tr>
              <tr v-if="isSender">
                <td class="align-middle text-right">
                  <i class="d-inline d-sm-none">
                    {{ $t("transaction.total_debited") }}
                  </i>
                  <i class="d-none d-sm-inline">
                    {{ $t("transaction.total_amount_debited") }}
                  </i>
                </td>
                <td class="align-middle text-danger text-left font-weight-bold">
                  {{ `${transfer.amount} ${transfer.currency}` }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i class="d-inline d-sm-none">{{ $t("common.date") }}</i>
                  <i class="d-none d-sm-inline">
                    {{ $t("common.creation_date") }}
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ transfer.created_at | moment("DD MMMM YYYY") }}
                  <small class="d-block">
                    <i class="fa fa-clock f-w-300 "></i>
                    {{ transfer.created_at | moment("H:m:s") }}
                  </small>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.status") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  <i :class="statusClassIcon"></i> {{ transfer.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="card-footer text-center">
            <button
              class="btn btn-primary"
              role="button"
              type="button"
              @click.prevent="close"
            >
              {{ $t("common.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="md-overlay" @click.prevent="close"></div>
  </div>
</template>

<script>
import { transferMixin } from "../../mixins/transferMixin";

export default {
  name: "TransferDetails",
  mixins: [transferMixin],
  methods: {
    close() {
      this.$router.back();
    },
  },
  computed: {
    isReceiver() {
      return this.isCurrentUser(this.transfer.receiver.id);
    },
  },
};
</script>

<style scoped></style>
