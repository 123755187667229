import { mapGetters } from "vuex";

export const transferMixin = {
  props: {
    transfer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["isCurrentUser"]),

    activeClass() {
      switch (this.transfer.status) {
        case "Succès":
          return "success";
        case "Echec":
          return "danger";
        case "En cours":
          return "info";
      }
    },
    statusClassIcon() {
      switch (this.transfer.status) {
        case "Succès":
          return "fas fa-thumbs-up text-success";
        case "Echec":
          return "fas fa-thumbs-down text-danger";
        case "En cours":
          return "fas fa-hourglass-half text-info";
      }
    },
    description() {
      if (this.isCurrentUser(this.transfer.receiver.id))
        return {
          title: "Reçu de ",
          email: this.transfer.sender.email,
        };
      else
        return {
          title: "Envoyé à ",
          email: this.transfer.receiver.email,
        };
    },
    getTransferClass() {
      if (this.isCurrentUser(this.transfer.receiver.id))
        return "text-success fa-long-arrow-alt-down";
      else return "text-danger fa-long-arrow-alt-up";
    },
    movementIcon() {
      if (this.isCurrentUser(this.transfer.receiver.id))
        return "fa-arrow-alt-circle-down";
      else return "fa-arrow-alt-circle-up";
    },

    isSender() {
      return this.isCurrentUser(this.transfer.sender.id);
    },
    base_amount() {
      return this.isSender && this.rate !== 1
        ? parseFloat(this.transfer.amount) -
            parseFloat(this.transfer.fee_amount)
        : this.transfer.base_amount;
    },
    currency() {
      return this.isSender
        ? this.transfer.currency
        : this.transfer.target_currency;
    },
  },
};
